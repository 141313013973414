import React from "react";
import { Home, About, Interactive, Community, Art, Writing, NotFound } from "./Pages";

export default function Webpage(props) {
	var component; 
	switch(props.name) {
		case "Home": component = Home;
		break;
		case "Community": component = Community;
		break;
		case "Interactive": component = Interactive;
		break;
		case "Art": component = Art;
		break;
		case "Writing": component = Writing;
		break;
		case "About": component = About;
		break;
		default: component = NotFound;
	}
	
	return (
	<div className="main">
		<h2 className="page-title">{props.name}</h2>
		{React.createElement(component)}
	</div>
	);
}