import React from "react";
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Webpage from "./Webpage";
import { Navblock, pageList } from "./Navblock";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
		
		<Router forceRefresh>
			<Navblock />
			
			<Switch>
				{pageList.map(page => {
					return (<Route key={page} exact path={page.path}>
						<Webpage name={page.title} />
					</Route>);
				})}
				<Route>
					<Webpage name="Not Found" />
				</Route>
			</Switch>
			
		</Router>
    </div>
  );
}

export default App;
