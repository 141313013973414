import React from "react";

export function Home() {
	return (
	<>
		<p>Hello, I'm Amari, a Brisbane-based creative from Singapore. My creative practice explores interactivity, multicursal narratives, and relationships between people and places.</p>
	</>
	);
}

export function Interactive() {
	return (
	<>
		<p>Here are some of my interactive works.</p>
	</>
	);
}

export function Community() {
	return (
	<>
		<p>I have organised and participated in multiple collaborative community projects.</p>
	</>
	);
}

export function Art() {
	return (
	<>
		<p>I am an illustrator who specialises in illustrations and sequential work.</p>
	</>
	);
}

export function Writing() {
	return (
	<>
		<p>I write. I have been published by Strange Horizons magazine. I have also self-published online.</p>
	</>
	);
}

export function About() {
	return (
	<>
		<p>I'm Amari, a Brisbane-based creative from Singapore. My work explores interactivity, multicursal narratives, and relationships between people and places.</p>
		
		<p>I have been a self-employed freelance artist since 2014, specialising in character art, music illustrations and background painting. In 2019, I added music composition to my repertoire, creating soundtracks for games and stories.</p>
		
		<p>In 2017, I graduated from Nanyang Technological University in Singapore with a Bachelor of Fine Arts (First Class Honours). During the course of my degree project, I created an interactive webcomic project, <i>Compass</i>, and wrote <a href="https://hdl.handle.net/10356/71086">a 5,000 word exegesis</a> documenting my research into hypercomics, and my experiments in developing a visual language for organising the infinite canvas for visual storytelling.</p>
		
		<p>I moved to Brisbane in 2018, where I commenced a master's degree in Interactive Media. Despite the pandemic, I graduated in 2020 with a Master of Fine Arts (Distinction) and attained the Griffith Award of Academic Excellence.</p>
	</>
	);
}

export function NotFound() {
	return (
	<>
		<p>This page cannot be found.</p>
	</>
	);
}