import React from "react";
import logo from './yun.png';
import NavBar from 'react-bootstrap/NavBar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from "react-router-dom";

export const pageList = [
	{
		title: "Home",
		path: "/"
	},
	{
		title: "About",
		path: "/about"
	},
	{
		title: "Interactive",
		path: "/interactive"
	},
	{
		title: "Art",
		path: "/art"
	},
	{
		title: "Writing",
		path: "/writing"
	},
	{
		title: "Community",
		path: "/community"
	}
];

export function Navblock() {
	
	const nav = pageList.map(page => {
		return (<NavLink
			className="nav-link top-nav"
			activeClassName="font-weight-bold"
			exact to={page.path}
			key={"nav-"+page.title.toLowerCase()}
		>
			{page.title}
		</NavLink>);
	});
	
	return (<NavBar bg="dark" variant="dark">
		<NavBar.Brand className="brand-title">
			<img src={logo} className="App-logo" alt="logo" /> Amari Low
		</NavBar.Brand>
		<Nav className="flex-wrap">
			{nav}
		</Nav>
	</NavBar>);
}